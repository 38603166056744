import { convertToCamelCase } from './constants'

export const requiredFields = {
  LMS: {
    name: { type: 'string' },
    email: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
    finalStatus: { type: 'string' },
  },
  ARISE: {
    name: { type: 'string' },
    email: { type: 'string' },
    certificateNo: { type: 'number' },
    finalStatus: { type: 'string' },
  },
  RMST: {
    name: { type: 'string' },
    email: { type: 'string' },
    address: { type: 'string' },
    enrollmentNo: { type: 'string' },
    certificateNo: { type: 'number' },
    firstSem: { type: 'number' },
    secondSem: { type: 'number' },
    finalStatus: { type: 'string' },
  },
  PCTRPI: {
    name: { type: 'string' },
    email: { type: 'string' },
    finalStatus: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
  },
  RELPRP: {
    name: { type: 'string' },
    email: { type: 'string' },
    finalStatus: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
  },
  FIRP: {
    name: { type: 'string' },
    email: { type: 'string' },
    finalStatus: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
  },
  MU: {
    name: { type: 'string' },
    email: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
    finalStatus: { type: 'string' },
  },
  QA: {
    name: { type: 'string' },
    email: { type: 'string' },
    finalStatus: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
  },
  CTRP: {
    name: { type: 'string' },
    email: { type: 'string' },
    finalStatus: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
  },
  RTCB: {
    name: { type: 'string' },
    email: { type: 'string' },
    finalStatus: { type: 'string' },
    organization: { type: 'string' },
    certificateNo: { type: 'number' },
  },
}

export const displayFields = {
  LMS: ['name', 'email', 'organization', 'certificateNo', 'finalStatus'],
  RMST: [
    'image',
    'name',
    'email',
    'address',
    'enrollmentNo',
    'certificateNo',
    'firstSem',
    'secondSem',
    'finalStatus',
  ],
  ARISE: ['name', 'email', 'finalStatus'],
  PCTRPI: ['name', 'email', 'finalStatus', 'organization', 'certificateNo'],
  RELPRP: ['name', 'email', 'finalStatus', 'organization', 'certificateNo'],
  FIRP: ['name', 'email', 'finalStatus', 'organization', 'certificateNo'],
  MU: ['name', 'email', 'organization', 'certificateNo', 'finalStatus'],
  QA: ['name', 'email', 'finalStatus', 'organization', 'certificateNo'],
  CTRP: ['name', 'email', 'finalStatus', 'organization', 'certificateNo'],
  RTCB: ['name', 'email', 'finalStatus', 'organization', 'certificateNo'],
}

export const validateExcelFormat = (type, rows) => {
  return new Promise((resolve, reject) => {
    try {
      if (rows.length === 0) {
        resolve({
          success: false,
          errors: ['Invalid excel'],
          message: 'Validation failed',
        })
      }
      const errors = []
      const validatedKeys = []

      console.log(type, rows)
      const keys = Object.keys(requiredFields[type])
      console.log('🚀 ~ returnnewPromise ~ keys:', keys)
      const excelKeys = rows[0].map((k) => convertToCamelCase(k))
      console.log('🚀 ~ returnnewPromise ~ excelKeys:', excelKeys)

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index]
        if (excelKeys.includes(key)) {
          const excelKeyIndex = excelKeys.findIndex(
            (excelKey) => excelKey === key
          )
          validatedKeys.push({ key, excelKeyIndex })
        } else {
          errors.push(`Excel file you have uploaded does not have ${key} row`)
        }
      }
      // console.log("🚀 ~ returnnewPromise ~ validatedKeys:", validatedKeys)

      const excelRows = rows.slice(1)

      for (let excelIdx = 0; excelIdx < excelRows.length; excelIdx++) {
        const _row = excelRows[excelIdx]
        for (let rowIdx = 0; rowIdx < validatedKeys.length; rowIdx++) {
          const _type = requiredFields[type][validatedKeys[rowIdx].key].type
          const _data = _row[validatedKeys[rowIdx].excelKeyIndex]
          // console.log("🚀 ~ returnnewPromise ~ _data:", _data)
          if (typeof _data !== _type) {
            errors.push(
              `Invalid data format found row: [${_row.join(', ')}], header: ${validatedKeys[rowIdx].key}, value: ${_data}`
            )
          }
          // console.log("🚀 ~ returnnewPromise ~ typeof(data):", typeof (_data))
        }
      }

      if (errors.length > 0) {
        console.log('🚀 ~ returnnewPromise ~ errors.length:', errors.length)
        resolve({
          success: false,
          errors: errors,
          message: 'Validation failed',
        })
      } else {
        resolve({
          success: true,
          message: 'Validation passed successfully',
        })
      }
    } catch (error) {
      console.log('🚀 ~ returnnewPromise ~ error:', error)
      resolve({
        success: false,
        errors: [JSON.stringify(error)],
        message: 'Validation failed',
      })
    }
  })
}
