import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Text,
  Flex,
  Image,
  Input,
  Button,
  Heading,
  Divider,
  VisuallyHidden,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Textarea,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'
import Logo from '../utils/IRMRISymbol.png'
import { UploadIcon } from '../utils/Icons'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import StudentService from '../apiServices/student.service'
import CertificatePreview from '../components/CertificatePreview'
import VerifyStudent from '../components/VerifyStudent'

function UploadForm() {
  const formRef = useRef(null)
  const {
    isOpen: previewIsOpen,
    onOpen: previewOnOpen,
    onClose: previewOnClose,
  } = useDisclosure()
  const {
    isOpen: verifyIsOpen,
    onOpen: verifyOnOpen,
    onClose: verifyOnClose,
  } = useDisclosure()
  const [studentData, setStudentData] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const cancelRef = React.useRef()
  const [loading, setLoading] = useState(false)
  const [enrollment, setEnrollment] = useState('')
  const [fetchedData, setFetchedData] = useState({})
  const [studentExists, setStudentExists] = useState(false)
  const [file, setFile] = useState(null)
  const [fileURL, setFileURL] = useState(null)
  const toast = useToast()

  const onClose = () => {
    setIsOpen(false)
  }

  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      const formData = new FormData()
      Object.entries(studentData).forEach(([key, value]) => {
        if (key !== 'enrollmentNo') {
          formData.append(key, value)
        }
      })
      formData.append('image', file)
      const response = await StudentService.updateStudentDetails(
        fetchedData?.studentId,
        formData
      )
      if (response?.status === true) {
        toast({
          title: 'Details Update',
          description: response?.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        })
        window.location.href = 'https://irmra.org/'
      } else {
        toast({
          title: 'Details Update',
          description: response?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        })
      }
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleStudentVerify = async () => {
    toast({
      title: '',
      description: "Sending otp...",
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    })
    const { data } = await StudentService.studentVerify({
      email: studentData?.email
    })
    if(data?.status){
      toast({
        title: '',
        description: data?.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      verifyOnOpen()
    }
  }

  //function to fetch the data from the server of a specific student based on his enrollment no
  const getUserDetails = async (enrollment) => {
    try {
      setLoading(true)
      if (enrollment) {
        const response = await StudentService.getStudentDetails(enrollment)
        if (response?.length > 0 && Array.isArray(response)) {
          const userData = response[0]
          console.log('🚀 ~ getUserDetails ~ userData:', userData)
          Object.entries(userData).forEach((obj) => {
            formRef?.current.setFieldValue(obj[0], obj[1])
          })
          setFetchedData(userData)
          setStudentExists(true)
        } else {
          setFetchedData({})
          setStudentExists(false)
        }
      } else {
        setFetchedData({})
        setStudentExists(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (enrollment && !studentExists) {
      getUserDetails(enrollment)
    }
  }, [enrollment, studentExists])

  useEffect(() => {
    if (fetchedData && Object.keys(fetchedData)?.length !== 0) {
      setStudentExists(true)
    } else {
      setStudentExists(false)
    }
  }, [fetchedData])

  // const handleFileChange = async (e) => {
  //   const currentFile = e.target.files[0]
  //   setFile(currentFile)

  //   const url = URL.createObjectURL(currentFile)
  //   setFileURL(url)
  // }

  const handleFileChange = async (e) => {
    const currentFile = e.target.files[0]
    if (!currentFile) return
    if (/(image\/jpeg)|(image\/png)/i.test(currentFile.type)) {
      setFile(currentFile)

      const url = URL.createObjectURL(currentFile)
      setFileURL(url)
    } else {
      toast({
        title: 'Unsupported file type.',
        description: 'Only JPG, JPEG, and PNG files should be uploaded.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
    }
  }

  return (
    <>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        bg="#DFDFDF"
        fontFamily={'Montserrat'}
      >
        <Flex
          flexDirection={'column'}
          maxW={{ base: '100%', lg: '450px' }}
          bg="white"
          borderRadius={'6px'}
          my="1rem"
        >
          <Flex
            style={{ justifyContent: 'center', alignItems: 'center' }}
            mt="1rem"
          >
            <Box p="1rem">
              <Flex justifyContent={'center'} alignItems="center">
                <Image src={Logo} boxSize="125px" />
              </Flex>
              <Heading fontSize="26px" mt="1rem" fontFamily={'Montserrat'}>
                Application Form 2023-24 (RMST)
              </Heading>
              <Text mt="1rem" fontSize="16px">
                Indian Rubber Manufacturers Research Institute A World Class
                Accredited Lab, Affiliated to DPIIT, Ministry of Commerce &
                Industry, Government of India
              </Text>
              <Divider />
              <Formik
                innerRef={formRef}
                initialValues={{
                  name: studentExists && fetchedData ? fetchedData?.name : '',
                  enrollmentNo: '',
                  address: '',
                  city: '',
                  state: '',
                  country: '',
                  pincode: '',
                  email: studentExists && fetchedData ? fetchedData?.email : '',
                  phone: '',
                }}
                onSubmit={async (values) => {
                  if (!file) {
                    toast({
                      title: 'Error',
                      description: 'Please upload an image file.',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                      position: 'top-right',
                    })
                  }
                  const finalObject = {
                    name: values.name,
                    enrollmentNo: values.enrollmentNo,
                    address: values.address,
                    email:
                      studentExists && fetchedData
                        ? fetchedData?.email
                        : values.email,
                    phone: values.phone,
                  }
                  setStudentData(finalObject)
                  if (file) {
                    setIsOpen(true)
                    return
                  }
                }}
                validationSchema={Yup.object().shape({
                  name: studentExists
                    ? Yup.string()
                    : Yup.string().required('Name is required'),
                  enrollmentNo: Yup.string().required(
                    'Enrollment no is required'
                  ),
                  address: Yup.string().required('Address is required'),
                  email: studentExists
                    ? Yup.string()
                    : Yup.string().email().required('Email is required'),
                  phone: Yup.string()
                    .required('Phone is required')
                    .min(10, 'Phone must be at least 10 characters')
                    .max(10, 'Phone must be 10 characters'),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props
                  return (
                    <Form>
                      <Box mt="2rem">
                        <Text
                          color={'#141D43'}
                          fontSize="md"
                          fontWeight={'700'}
                          fontFamily={'font.roboto'}
                        >
                          Enrollment No. &nbsp;
                          <Text as="span" color="red">
                            *
                          </Text>
                        </Text>
                        <Input
                          type="text"
                          color={'#141D43'}
                          marginTop="10px"
                          bg={'#fff'}
                          w="100%"
                          id="enrollmentNo"
                          name="enrollmentNo"
                          placeholder="Enter enrollment no"
                          value={values.enrollmentNo}
                          onChange={(e) => {
                            const newEnrollment = e.target.value
                            handleChange(e)
                            setEnrollment(newEnrollment)
                            getUserDetails(newEnrollment)
                          }}
                          onBlur={handleBlur}
                          fontSize={'14px'}
                          border="1px solid #9E9E9E"
                          _focusVisible={
                            errors.enrollmentNo && touched.enrollmentNo
                              ? {
                                border: '1px solid red !important',
                              }
                              : {
                                border: '1px solid #6C3B1C',
                              }
                          }
                        />
                        {errors.enrollmentNo && touched.enrollmentNo && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.enrollmentNo}
                          </Box>
                        )}
                      </Box>
                      <Box mt="2rem">
                        <Text
                          color={'#141D43'}
                          fontSize="md"
                          fontWeight={'700'}
                          fontFamily={'font.roboto'}
                        >
                          Name &nbsp;
                          <Text as="span" color="red">
                            *
                          </Text>
                        </Text>
                        <Input
                          type="text"
                          color={'#141D43'}
                          marginTop="10px"
                          bg={'#fff'}
                          w="100%"
                          id="name"
                          name="name"
                          placeholder="Enter name"
                          defaultValue={
                            studentExists ? fetchedData?.name : values?.name
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={'14px'}
                          border="1px solid #9E9E9E"
                          _focusVisible={
                            errors.name && touched.name
                              ? {
                                border: '1px solid red !important',
                              }
                              : {
                                border: '1px solid #6C3B1C',
                              }
                          }
                        />
                        {errors.name && touched.name && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.name}
                          </Box>
                        )}
                      </Box>
                      <Box mt="2rem">
                        <Text
                          color={'#141D43'}
                          fontSize="md"
                          fontWeight={'700'}
                          fontFamily={'font.roboto'}
                        >
                          Address for sending certificate &nbsp;
                          <Text as="span" color="red">
                            *
                          </Text>
                        </Text>
                        <Textarea
                          type="text"
                          color={'#141D43'}
                          marginTop="10px"
                          bg={'#fff'}
                          w="100%"
                          id="address"
                          name="address"
                          placeholder="Enter your full address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={'14px'}
                          border="1px solid #9E9E9E"
                          _focusVisible={
                            errors.address && touched.address
                              ? {
                                border: '1px solid red !important',
                              }
                              : {
                                border: '1px solid #6C3B1C',
                              }
                          }
                        />
                        {errors.address && touched.address && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.address}
                          </Box>
                        )}
                      </Box>
                      <Box mt="2rem">
                        <Text
                          color={'#141D43'}
                          fontSize="md"
                          fontWeight={'700'}
                          fontFamily={'font.roboto'}
                        >
                          Email &nbsp;
                          <Text as="span" color="red">
                            *
                          </Text>
                        </Text>
                        <Input
                          type="email"
                          color={'#141D43'}
                          marginTop="10px"
                          bg={'#fff'}
                          w="100%"
                          id="email"
                          name="email"
                          isDisabled={
                            studentExists &&
                            Object.keys(fetchedData)?.length !== 0
                          }
                          placeholder="Enter email"
                          value={
                            studentExists ? fetchedData?.email : values?.email
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={'14px'}
                          border="1px solid #9E9E9E"
                          _focusVisible={
                            errors.email && touched.email
                              ? {
                                border: '1px solid red !important',
                              }
                              : {
                                border: '1px solid #6C3B1C',
                              }
                          }
                        />
                        {errors.email && touched.email && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.email}
                          </Box>
                        )}
                      </Box>
                      <Box mt="2rem">
                        <Text
                          color={'#141D43'}
                          fontSize="md"
                          fontWeight={'700'}
                          fontFamily={'font.roboto'}
                        >
                          Phone No. &nbsp;
                          <Text as="span" color="red">
                            *
                          </Text>
                        </Text>
                        <Input
                          type="number"
                          color={'#141D43'}
                          marginTop="10px"
                          bg={'#fff'}
                          w="100%"
                          id="phone"
                          name="phone"
                          placeholder="Enter phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={'14px'}
                          border="1px solid #9E9E9E"
                          _focusVisible={
                            errors.phone && touched.phone
                              ? {
                                border: '1px solid red !important',
                              }
                              : {
                                border: '1px solid #6C3B1C',
                              }
                          }
                        />
                        {errors.phone && touched.phone && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.phone}
                          </Box>
                        )}
                      </Box>
                      <Box mt="2rem">
                        <Text
                          color={'#141D43'}
                          fontSize="md"
                          fontWeight={'700'}
                          fontFamily={'font.roboto'}
                        >
                          Candidate's image &nbsp;
                          <Text as="span" color="red">
                            *
                          </Text>
                        </Text>
                        <Flex
                          mt="10px"
                          direction="column"
                          align="center"
                          justifyContent={'center'}
                          textAlign="center"
                          w="150px"
                          h="65px"
                          // bg="#C7C2FF"
                          // _hover={{ bg: '#C7C2FF', cursor: 'pointer' }}
                          border="0.5px solid black"
                          cursor="pointer"
                          color="black"
                          borderRadius={'8px'}
                          htmlFor="file-upload"
                          as="label"
                        >
                          <UploadIcon />
                          <VisuallyHidden>
                            <input
                              //   ref={inputRef}
                              id="file-upload"
                              type="file"
                              onInput={(e) => handleFileChange(e)}
                              accept="image/jpeg, image/png, image/jpg"
                            />
                          </VisuallyHidden>
                          Upload
                        </Flex>
                        {errors.image && touched.image && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.image}
                          </Box>
                        )}
                      </Box>

                      {file ? (
                        <Box position="relative" mt="2rem">
                          <Image src={fileURL} borderRadius={'8px'} />
                          <Text
                            color="#909090"
                            position={'absolute'}
                            top="1"
                            zIndex="1"
                            fontSize={'26px'}
                            fontWeight="bold"
                          >
                            Preview Image
                          </Text>
                        </Box>
                      ) : (
                        ''
                      )}
                      <Button
                        mt="2rem"
                        w="100%"
                        bg="#eee"
                        color="#000"
                        onClick={() => previewOnOpen()}
                      >
                        Preview
                      </Button>
                      <Button
                        mt="1rem"
                        w="100%"
                        bg="#0A5C36"
                        color="white"
                        mb="10px"
                        onClick={() => handleSubmit()}
                      >
                        Submit Form
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Submit Details
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure these are the final details? You can't undo this
              action afterwards.
              <Box mt="2rem">
                <Box>
                  <Text
                    color={'#141D43'}
                    fontSize="md"
                    fontWeight={'700'}
                    fontFamily={'font.roboto'}
                  >
                    Enrollment No. &nbsp;
                    <Text as="span" color="red">
                      *
                    </Text>
                  </Text>
                  <Text mt="10px">{studentData?.enrollmentNo}</Text>
                </Box>
                <Box mt="1rem">
                  <Text
                    color={'#141D43'}
                    fontSize="md"
                    fontWeight={'700'}
                    fontFamily={'font.roboto'}
                  >
                    Name &nbsp;
                    <Text as="span" color="red">
                      *
                    </Text>
                  </Text>
                  <Text mt="10px">{studentData?.name}</Text>
                </Box>
                <Box mt="1rem">
                  <Text
                    color={'#141D43'}
                    fontSize="md"
                    fontWeight={'700'}
                    fontFamily={'font.roboto'}
                  >
                    Address &nbsp;
                    <Text as="span" color="red">
                      *
                    </Text>
                  </Text>
                  <Text mt="10px">{studentData?.address}</Text>
                </Box>
                {/* <Flex mt="1rem" gap="2rem">
                  <Box>
                    <Text
                      color={'#141D43'}
                      fontSize="md"
                      fontWeight={'700'}
                      fontFamily={'font.roboto'}
                    >
                      City &nbsp;
                      <Text as="span" color="red">
                        *
                      </Text>
                    </Text>
                    <Text mt="10px">{studentData?.city}</Text>
                  </Box>
                  <Box>
                    <Text
                      color={'#141D43'}
                      fontSize="md"
                      fontWeight={'700'}
                      fontFamily={'font.roboto'}
                    >
                      State/Union territory &nbsp;
                      <Text as="span" color="red">
                        *
                      </Text>
                    </Text>
                    <Text mt="10px">{studentData?.state}</Text>
                  </Box>
                </Flex>
                <Flex mt="1rem" gap="2rem">
                  <Box>
                    <Text
                      color={'#141D43'}
                      fontSize="md"
                      fontWeight={'700'}
                      fontFamily={'font.roboto'}
                    >
                      Country &nbsp;
                      <Text as="span" color="red">
                        *
                      </Text>
                    </Text>
                    <Text mt="10px">{studentData?.country}</Text>
                  </Box>
                  <Box>
                    <Text
                      color={'#141D43'}
                      fontSize="md"
                      fontWeight={'700'}
                      fontFamily={'font.roboto'}
                    >
                      Pin Code &nbsp;
                      <Text as="span" color="red">
                        *
                      </Text>
                    </Text>
                    <Text mt="10px">{studentData?.pincode}</Text>
                  </Box>
                </Flex> */}
                <Box mt="1rem">
                  <Text
                    color={'#141D43'}
                    fontSize="md"
                    fontWeight={'700'}
                    fontFamily={'font.roboto'}
                  >
                    Email &nbsp;
                    <Text as="span" color="red">
                      *
                    </Text>
                  </Text>
                  <Text mt="10px">{studentData?.email}</Text>
                </Box>
                <Box mt="1rem">
                  <Text
                    color={'#141D43'}
                    fontSize="md"
                    fontWeight={'700'}
                    fontFamily={'font.roboto'}
                  >
                    Phone No. &nbsp;
                    <Text as="span" color="red">
                      *
                    </Text>
                  </Text>
                  <Text mt="10px">{studentData?.phone}</Text>
                </Box>
                <Box mt="1rem">
                  <Text
                    color={'#141D43'}
                    fontSize="md"
                    fontWeight={'700'}
                    fontFamily={'font.roboto'}
                  >
                    Candidate's image &nbsp;
                    <Text as="span" color="red">
                      *
                    </Text>
                  </Text>
                  <Box position="relative" mt="10px">
                    <Image src={fileURL} borderRadius={'8px'} />
                    <Text
                      color="#909090"
                      position={'absolute'}
                      top="1"
                      zIndex="1"
                      fontSize={'26px'}
                      fontWeight="bold"
                    >
                      Preview Image
                    </Text>
                  </Box>
                </Box>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={loading}>
                Cancel
              </Button>
              <Button
                bg="#6C3B1C"
                color="white"
                onClick={handleStudentVerify}
                ml={3}
                isDisabled={loading}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <CertificatePreview
        isOpen={previewIsOpen}
        onClose={previewOnClose}
        onOpen={previewOnOpen}
        formRef={formRef}
        enrollment={enrollment}
        fileURL={fileURL}
        fetchedData={fetchedData}
      />
      <VerifyStudent
        email={studentData?.email}
        isOpen={verifyIsOpen}
        onOpen={verifyOnOpen}
        onClose={verifyOnClose}
        handleFormSubmit={handleFormSubmit}
      />
    </>
  )
}

export default UploadForm
