import React from 'react'
import {
  Box,
  Text,
  Flex,
  Image,
  Link,
  useSteps,
  Spinner,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Logo from '../utils/IRMRISymbol.png'
import StudentService from '../apiServices/student.service'
import { useParams } from 'react-router'
import VerificationCard from '../components/VerificationCard'
import PDFViewer from '../components/PDFViewer'
import { getFinancialYearSession } from '../utils/constants'

function StudentVerify() {
  const { id } = useParams()
  const [dataFetched, setDataFetched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [studentIntegrityData, setStudentIntegrityData] = useState({})
  const [isBlockchainDataPresent, SetIsBlockchainDataPresent] = useState(false)
  const [certificateLink, setCertificateLink] = useState('')
  const [showIframe, setShowIframe] = useState(false)
  const googleViewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(certificateLink)}`
  const [steps, setSteps] = useState([
    {
      key: 'enrollmentNumberValid',
      title: 'Certificate ID check',
      description: 'ID verified Successfully',
    },
    {
      key: 'studentIdValid',
      title: 'Student Details check',
      description: 'Student verified Successfully ',
    },
    {
      key: 'courseNameValid',
      title: 'Course Name Check',
      description: 'Course name verified Successfully ',
    },
    {
      key: 'resultStatusValid',
      title: 'Result Status',
      description: 'Results verified Successfully ',
    },
    {
      key: 'integrityValid',
      title: 'Integrity Check',
      description: 'Integrity check verified Successfully ',
    },
  ])

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const integrityDataFetched =
          await StudentService.fetchStudentIntegrity(id)
        if (integrityDataFetched?.data) {
          const updatedSteps = steps.map((step) => ({
            ...step,
            status: integrityDataFetched?.data?.traceSteps[step.key] || false,
          }))
          setCertificateLink(integrityDataFetched?.certificatelink)
          setSteps(updatedSteps)
          setDataFetched(true)
          setStudentIntegrityData(integrityDataFetched?.data)
        } else {
          SetIsBlockchainDataPresent(true)
        }
        console.log(
          '➡️ ~ fetchData ~ integrityDataFetched:',
          integrityDataFetched
        )
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIframe(true)
    }, 4000) // Delay in milliseconds

    return () => clearTimeout(timer) // Clean up the timer
  }, [certificateLink])

  return (
    <>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        fontFamily={'Open Sans'}
      >
        <Flex flexDirection={'column'} maxW={{ base: '100%', lg: '375px' }}>
          <Flex
            style={{ justifyContent: 'center', alignItems: 'center' }}
            mt="1rem"
          >
            <Image src={Logo} boxSize="125px" />
          </Flex>
          {loading ? (
            <Box marginTop={'2rem'} textAlign={'center'}>
              <Spinner width="50px" height="50px" color="#6C3B1C" />
              <Text fontSize="16px" fontWeight={'700'} marginTop={'10px'}>
                Loading the Student Details...
              </Text>
            </Box>
          ) : isBlockchainDataPresent ? (
            <Flex justifyContent={'center'} alignItems="center" mt="1rem">
              <Text fontWeight={'bold'}>
                Please wait while the data is being pushed to the blockchain.
              </Text>
            </Flex>
          ) : (
            <>
              <Box p={'1.5rem'}>
                <Text fontSize={'24px'}>
                  <Text fontWeight={'bold'}>
                    {studentIntegrityData?.certificateDetails?.studentName}
                  </Text>
                  {studentIntegrityData?.certificateDetails
                    ?.studentenrollmentNumber !== 'NAN'
                    ? `(${studentIntegrityData?.certificateDetails?.studentenrollmentNumber})`
                    : ''}
                  Successfully completed the course{' '}
                  {studentIntegrityData?.certificateDetails?.courseName}{' '}
                  {getFinancialYearSession(
                    studentIntegrityData?.certificateDetails?.courseStart,
                    studentIntegrityData?.certificateDetails?.courseEnd
                  )}
                </Text>
              </Box>
              <Box>
                <PDFViewer url={certificateLink} />
              </Box>
              <VerificationCard
                studentIntegrityData={studentIntegrityData}
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                dataFetched={dataFetched}
              />
            </>
          )}

          <Flex
            style={{ justifyContent: 'center', alignItems: 'center' }}
            my="2rem"
          >
            <Link
              borderRadius={'6px'}
              color="white"
              h="33px"
              w="100%"
              fontSize="16px"
              bg="#007AFF"
              href="https://irmra.org/"
              isExternal
            >
              <Text textAlign="center" mt="5px">
                Visit IRMRI Website
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default StudentVerify
