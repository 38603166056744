import {
  Card,
  CardBody,
  Flex,
  Text,
  useToast,
  Box,
  Spinner,
} from '@chakra-ui/react'
import { memo, useEffect, useState } from 'react'
import StudentService from '../apiServices/student.service'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../store/App/AppSlice'

const BatchListing = ({
  currentBatchId,
  setCurrentBatchId,
  setCourseTag,
}) => {
  const toast = useToast()
  const user = useSelector(selectCurrentUser)
  const [loading, setLoading] = useState(false)
  const [batches, setBatches] = useState([])
  const [courses, setCourses] = useState([])
  const getData = async () => {
    try {
      setLoading(true)
      const { data } = await StudentService.getPreviousBatchesList()
      console.log("@data: ", data);

      if (data.status === true) {
        setBatches(data?.data.filter((batch) => batch.place == user?.location))
        if (data?.data.length > 0) {
          setCurrentBatchId(data?.data[0]._id)
        }
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message ?? 'Something went wrong please try later!',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
    } finally {
      setLoading(false)
    }
  }
  const getStudent = (id) => {
    setCurrentBatchId(id)
  }
  useEffect(() => {
    getData()
    setCourses(JSON.parse(localStorage.getItem('courses')))
  }, [])
  return (
    <Flex className="batches" overflow={'scroll'} mb={5}>
      {batches.map((batch, idx) => (
        <Card
          onClick={() => getStudent(batch._id)}
          key={idx}
          minW={250}
          m={2}
          bg={batch?._id === currentBatchId ? '#E3F5FF' : '#fff'}
          cursor={'pointer'}
          borderRadius={15}
          boxShadow={
            batch?._id === currentBatchId
              ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
              : ''
          }
        >
          <CardBody>
            <Text fontWeight={600} my={2}>Name: {batch.batchName}</Text>
            <Text fontSize={16}>
              Course: {courses.find(course => course?._id === batch?.courseId)?.courseTag}
            </Text>
          </CardBody>
        </Card>
      ))}
      {loading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="9999"
        >
          <Flex align="center" justify="center" flexDir="column">
            <Spinner size="xl" thickness="5px" speed="0.5s" color="#6C3B1C" />
            <Text fontWeight="bold" mt="1rem" color="#6C3B1C">
              Loading...
            </Text>
          </Flex>
        </Box>
      )}
    </Flex>
  )
}

export default memo(BatchListing)
