import {
  Box,
  Button,
  Input,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import StudentService from '../apiServices/student.service'
import { useState } from 'react'


const VerifyStudent = ({ onClose, isOpen, email, handleFormSubmit }) => {
  const [otp, setOtp] = useState("")
  const toast = useToast()
  const handleOtpSubmit = async () => {
    try {
      const {data} = await StudentService.studentVerifyOtp({
        email,otp
      })
     if(data.status){
      handleFormSubmit()
      onClose()
     }
    } catch (error) {
      toast({
        title: 'Details Update',
        description: error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    }
  }
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display={'flex'} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
            <Input
              textAlign={'center'}
              w={250}
              placeholder='Enter Otp...'
              onChange={(e) => setOtp(e.target.value)}
            />
          <Button onClick={handleOtpSubmit} mt={5} w={250}>Submit</Button>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default VerifyStudent