import { CurrentBatches, Dashboard } from './Icons'

export const RESPONSE_STATUS = {
  SUCCESS: true,
  FAILED: false,
}

export const menuItems = [
  {
    id: 1,
    text: 'Overview',
    path: '/overview',
    icon: (
      <Dashboard _h={{ '> path': { fill: 'white' } }} w={'10px'} h={'10px'} />
    ),
  },
  {
    id: 2,
    text: 'Batches',
    path: '/batches',
    icon: (
      <CurrentBatches
        _h={{ '> path': { fill: 'white' } }}
        w={'30px'}
        h={'30px'}
      />
    ),
  },
]

export function extractPath(url) {
  // This regex matches the path and the query string parts of a URL
  const regex =
    /(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)?(?:[^\/\n]*)?([^?#\n]*)(\??[^#\n]*)?/
  const matches = url.match(regex)

  // matches[2] is the path, matches[3] is the query string
  return (matches && matches[2]) || ''
}

export function getCookie(name) {
  let cookieArray = document.cookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookiePair = cookieArray[i].split('=')
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1])
    }
  }
  return null
}

export const convertToCamelCase = (str) => {
  // Convert the string to lowercase
  let lowerCaseStr = str.toLowerCase()

  // Split the string by spaces
  let words = lowerCaseStr.split(' ')

  // Capitalize the first letter of each word except the first one
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  // Join the words back together
  let camelCaseStr = words.join('')

  return camelCaseStr
}

export const capitalizedText = (inputText) =>
  inputText
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(' ')

export const camelCaseToSpaced = (str) => {
  // Split the string by capital letters and join with a space
  let spacedString = str.replace(/([A-Z])/g, ' $1').trim()

  // Capitalize the first letter of each word
  spacedString = spacedString.replace(/\b\w/g, (char) => char.toUpperCase())

  return spacedString
}

export const camelToTitle = (camelCaseString) => {
  return (
    camelCaseString
      // Insert a space before all capital letters
      .replace(/([A-Z])/g, ' $1')
      // Capitalize the first letter
      .replace(/^./, (str) => str.toUpperCase())
  )
}

const generateYears = () => {
  const currentYear = new Date().getFullYear()
  const years = []
  years.push({ label: currentYear - 1, value: currentYear - 1 })
  years.push({ label: currentYear - 2, value: currentYear - 2 })
  for (let i = 0; i <= 10; i++) {
    years.push({ label: currentYear + i, value: currentYear + i })
  }
  return years
}

export const requiredEditInputFields = {
  // certification courses
  ARISE: {
    name: { type: 'text', placeholder: 'Enter name' },
    email: { type: 'text', placeholder: 'Enter email' },
    finalStatus: {
      type: 'dropdown',
      placeholder: 'Select final status',
      values: [{ label: 'Attented', value: 'ATTENTED' }],
    },
  },
  RMST: {
    name: { type: 'text', placeholder: 'Enter name' },
    email: { type: 'text', placeholder: 'Enter email' },
    firstSem: { type: 'text', placeholder: 'Enter first semester marks' },
    secondSem: { type: 'text', placeholder: 'Enter second semester marks' },
    finalStatus: {
      type: 'dropdown',
      placeholder: 'Select final status',
      values: [
        { label: 'Pass', value: 'PASS' },
        { label: 'Fail', value: 'FAIL' },
      ],
    },
  },
  // technical courses
  LMS: {
    name: { type: 'text', placeholder: 'Enter name' },
    email: { type: 'text', placeholder: 'Enter email' },
    organization: { type: 'text', placeholder: 'Enter organization' },
    certificateNo: { type: 'text', placeholder: 'Enter certificate no' },
    finalStatus: {
      type: 'dropdown',
      placeholder: 'Select final status',
      values: [
        { label: 'Pass', value: 'PASS' },
        { label: 'Fail', value: 'FAIL' },
      ],
    },
  },
  MU: {
    name: { type: 'text', placeholder: 'Enter name' },
    email: { type: 'text', placeholder: 'Enter email' },
    organization: { type: 'text', placeholder: 'Enter organization' },
    certificateNo: { type: 'text', placeholder: 'Enter certificate no' },
    finalStatus: {
      type: 'dropdown',
      placeholder: 'Select final status',
      values: [
        { label: 'Pass', value: 'PASS' },
        { label: 'Fail', value: 'FAIL' },
      ],
    },
  },
  // non-technical courses
  QA: {
    name: { type: 'text', placeholder: 'Enter name...' },
    email: { type: 'text', placeholder: 'Enter email...' },
    finalStatus: { type: 'text', placeholder: 'Enter final status...' },
    organization: { type: 'text', placeholder: 'Enter organization...' },
  },
  CTRP: {
    name: { type: 'text', placeholder: 'Enter name...' },
    email: { type: 'text', placeholder: 'Enter email...' },
    finalStatus: { type: 'text', placeholder: 'Enter final status...' },
    organization: { type: 'text', placeholder: 'Enter organization...' },
  },
  RTCB: {
    name: { type: 'text', placeholder: 'Enter name...' },
    email: { type: 'text', placeholder: 'Enter email...' },
    finalStatus: { type: 'text', placeholder: 'Enter final status...' },
    organization: { type: 'text', placeholder: 'Enter organization...' },
  },
  PCTRPI: {
    organization: { type: 'text', placeholder: 'Enter organization' },
    certificateTitle: { type: 'text', placeholder: 'Enter certificate title' },
  },
  RELPRP: {
    organization: { type: 'text', placeholder: 'Enter organization' },
    certificateTitle: { type: 'text', placeholder: 'Enter certificate title' },
  },
  FIRP: {
    organization: { type: 'text', placeholder: 'Enter organization' },
    certificateTitle: { type: 'text', placeholder: 'Enter certificate title' },
  },
}

export const courseInfoInputFields = {
  // certification courses
  ARISE: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
  },
  RMST: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: {
      type: 'dropdown',
      placeholder: 'Enter start date...',
      values: generateYears(),
    },
    sessionEnd: {
      type: 'dropdown',
      placeholder: 'Enter end date...',
      values: generateYears(),
    },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
  },
  // technical courses
  LMS: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
  MU: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
  // non-technical courses
  QA: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
  CTRP: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
  RTCB: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
  PCTRPI: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
  RELPRP: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
  FIRP: {
    place: {
      type: 'dropdown',
      placeholder: 'Enter place',
      values: [
        { label: 'Thane', value: 'Thane' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'West Bengal', value: 'West Bengal' },
      ],
    },
    sessionStart: { type: 'date', placeholder: 'Enter start date...' },
    sessionEnd: { type: 'date', placeholder: 'Enter end date...' },
    batchName: { type: 'text', placeholder: 'Enter batch name' },
    venue: { type: 'text', placeholder: 'Enter venue' },
  },
}

const payloadFields = {
  // checked
  LMS: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: [
      'email',
      'exam1',
      'exam2',
      'exam3',
      'exam4',
      'finalStatus',
      'name',
      'certificateNo',
    ],
  },
  ARISE: {
    profile: ['email', 'name'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: ['name', 'email', 'certificateNo', 'finalStatus'],
  },
  MU: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: [
      'email',
      'exam1',
      'exam2',
      'exam3',
      'exam4',
      'finalStatus',
      'name',
      'certificateNo',
    ],
  },
  RMST: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: [
      'email',
      'finalStatus',
      'name',
      'certificateNo',
      'firstSem',
      'secondSem',
      'enrollmentNo',
    ],
  },
  PCTRPI: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: [
      'email',
      'exam1',
      'exam2',
      'exam3',
      'exam4',
      'finalStatus',
      'name',
      'certificateNo',
    ],
  },
  RELPRP: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: [
      'email',
      'exam1',
      'exam2',
      'exam3',
      'exam4',
      'finalStatus',
      'name',
      'certificateNo',
    ],
  },
  FIRP: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: [
      'email',
      'exam1',
      'exam2',
      'exam3',
      'exam4',
      'finalStatus',
      'name',
      'certificateNo',
    ],
  },
  QA: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: ['email', 'finalStatus', 'name', 'certificateNo'],
  },
  CTRP: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: ['email', 'finalStatus', 'name', 'certificateNo'],
  },
  RTCB: {
    profile: ['email', 'name', 'organization'],
    certificate: [
      'courseId',
      'batchName',
      'place',
      'venue',
      'sessionStart',
      'sessionEnd',
    ],
    marksheet: ['email', 'finalStatus', 'name', 'certificateNo'],
  },
}

export const extractDataArray = (key, arrayData, objectData) => {
  if (!payloadFields[key]) {
    throw new Error(`Key ${key} not found in payloadFields`)
  }

  const {
    profile: profileFields,
    certificate: certificateFields,
    marksheet: marksheetFields,
  } = payloadFields[key]

  const profiles = arrayData.map((item) => {
    const profile = {}
    profileFields.forEach((field) => {
      if (field in item) {
        profile[field] = item[field]
      }
    })
    return profile
  })

  const certificates = arrayData.map((item) => {
    const certificate = {}
    certificateFields.forEach((field) => {
      if (field in item) {
        certificate[field] = item[field]
      } else if (field in objectData) {
        certificate[field] = objectData[field]
      }
    })
    return certificate
  })

  const marksheets = arrayData.map((item) => {
    const marksheet = {}
    marksheetFields.forEach((field) => {
      if (field in item) {
        marksheet[field] = item[field]
      }
    })
    return marksheet
  })

  return {
    profile: profiles,
    certificate: certificates[0],
    marksheet: marksheets,
  }
}

export function getFinancialYearSession(startDate, endDate) {
  try {
    const start = new Date(startDate)
    const end = new Date(endDate)

    if (isNaN(start) || isNaN(end)) {
      throw new Error('Invalid date format')
    }

    const startMonth = start.getMonth() + 1
    const startYear = start.getFullYear()
    const endMonth = end.getMonth() + 1
    const endYear = end.getFullYear()

    // If both dates are in same financial year → return that FY
    const startFYStart = startMonth >= 4 ? startYear : startYear - 1
    const startFYEnd = startFYStart + 1

    const endFYStart = endMonth >= 4 ? endYear : endYear - 1
    const endFYEnd = endFYStart + 1

    // If same financial year → return start's FY
    if (startFYStart === endFYStart) {
      return `${startFYStart}-${startFYEnd.toString().slice(-2)}`
    }

    // Else, return based on endDate
    return `${endFYStart}-${endFYEnd.toString().slice(-2)}`
  } catch (error) {
    return ''
  }
}
