import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VisuallyHiddenInput,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import StudentService from '../apiServices/student.service'
import { validateExcelFormat } from '../utils/excelValidators'
import readXlsxFile from 'read-excel-file'
import { convertToCamelCase } from '../utils/constants'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUploadedStudents } from '../store/Student/StudentSlice'
import routes from '../utils/routes'

const UploadExcelModal = ({ isOpen, onClose }) => {
  const inputRef = useRef(null)
  const toast = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [courseType, setCourseType] = useState('')
  const [courseId, setCourseId] = useState('')
  const [courseTag, setCourseTag] = useState('')
  const [courses, setCourses] = useState([])
  const [certificatesNo, setCertificatesNo] = useState([])
  const [errors, setErrors] = useState([])

  const handleDownloadExcel = () => {
    window.location.href = `/excels/${courseTag}.xlsx`
  }

  const handleInputFileChange = async () => {
    setLoading(true)
    if (!inputRef?.current.files[0]) {
      setLoading(false)
      toast({
        title: 'Error',
        description: 'Please select a file for upload.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    const fileName = inputRef.current.files[0].name
    const fileExtension = fileName.split('.').pop().toLowerCase()

    const allowedFileTypes = ['xls', 'xlsx', 'xlsm']
    if (!allowedFileTypes.includes(fileExtension)) {
      toast({
        title: 'Error',
        description:
          'Only XLS, XLSX, and XLSM file types are allowed for upload.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      setLoading(false)
      return
    }

    if (inputRef.current && inputRef.current.files) {
      try {
        readXlsxFile(inputRef?.current.files[0]).then(async (rows) => {
          const response = await validateExcelFormat(courseTag, rows)
          console.log('🚀 ~ readXlsxFile ~ response:', response)
          if (response.success === false) {
            setErrors(response.errors)
            return
          }

          let filteredStudents = []
          let types = []
          let certificateNos = []

          rows.slice(1).forEach((student) => {
            let studentObj = {}
            rows[0].forEach((heading, index) => {
              if (!types.includes(convertToCamelCase(heading))) {
                types.push(convertToCamelCase(heading))
              }
              const key = convertToCamelCase(heading)
              studentObj[key] = student[index]
              if (key === 'certificateNo') {
                certificateNos.push(student[index])
              }
            })
            filteredStudents.push(studentObj)
          })

          localStorage.setItem('types', JSON.stringify(types))
          dispatch(setUploadedStudents(filteredStudents))
          setCertificatesNo(certificateNos)
          navigate(routes.UploadStudents)
        })
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        })
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }
    }
  }

  const getCourseTypes = async () => {
    const { data } = await StudentService.getCourses()
    setCourses(data.data)
    localStorage.setItem('courses', JSON.stringify(data.data) ?? JSON.stringify([]))
  }

  useEffect(() => {
    getCourseTypes()
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Excel</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Select
              name={'courseId'}
              placeholder="Please select course type..."
              onChange={(e) => {
                setCourseId(e.target.value)
                setCourseType(e.target.value)
                const selectedOption = e.target.selectedOptions[0]
                const courseTag = selectedOption.getAttribute('data-tag')
                setCourseTag(courseTag)
                localStorage.setItem('courseTag', courseTag)
              }}
            >
              {courses &&
                courses
                  .filter(
                    (course) =>
                      !['Technical', 'NonTechnical'].includes(course.courseType)
                  )
                  .map((course) => (
                    <option
                      key={course._id}
                      data-tag={course.courseTag}
                      value={course._id}
                    >
                      {course?.courseName}
                    </option>
                  ))}
              <option value={'Technical'}>Technical</option>
              <option value={'NonTechnical'}>Non Technical</option>
            </Select>
            {['Technical', 'NonTechnical'].includes(courseType) && (
              <>
                <Text my={2}>Course</Text>
                <Select
                  name={'courseId'}
                  placeholder="Please select course..."
                  onChange={(e) => {
                    setCourseId(e.target.value)
                    const selectedOption = e.target.selectedOptions[0]
                    const courseTag = selectedOption.getAttribute('data-tag')
                    setCourseTag(courseTag)
                    localStorage.setItem('courseTag', courseTag)
                  }}
                >
                  {courses &&
                    courses
                      .filter((course) => courseType === course.courseType)
                      .map((course) => (
                        <option
                          key={course._id}
                          data-tag={course.courseTag}
                          value={course._id}
                        >
                          {course?.courseName}
                        </option>
                      ))}
                </Select>
              </>
            )}
            {errors &&
              errors.map((_err, idx) => (
                <Text key={idx} color={'red'}>
                  {_err}
                </Text>
              ))}
            {courseId !== '' && (
              <>
                <Flex
                  onClick={handleDownloadExcel}
                  w={'100%'}
                  h={50}
                  alignItems={'center'}
                  justifyContent={'center'}
                  border={'1px solid #eee'}
                  borderRadius={5}
                  mt={5}
                  cursor={'pointer'}
                >
                  Download Sample
                </Flex>
                <Flex
                  onClick={() => inputRef.current.click()}
                  w={'100%'}
                  h={50}
                  alignItems={'center'}
                  justifyContent={'center'}
                  border={'1px solid #eee'}
                  borderRadius={5}
                  mt={5}
                  cursor={'pointer'}
                >
                  <VisuallyHiddenInput
                    onChange={handleInputFileChange}
                    type="file"
                    ref={inputRef}
                  />
                  Click here to upload excel
                </Flex>
              </>
            )}
          </Box>
          {loading && (
            <Flex
              flexDir={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Image src="https://cdn.dribbble.com/users/1478651/screenshots/6379052/16.gif" />
              Fetching data from the uploaded file...
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="ghost">Upload</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UploadExcelModal
