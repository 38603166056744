import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import StudentService from '../apiServices/student.service'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Enrolled Students Chart',
    },
  },
}

export function VisualChart(props) {
  const [courses, setCourses] = useState([])
  const fetchStudents = async () => {
    console.log("@fetchStudents: ");
    
    try {
      const getCoursesResponse = await StudentService.getCourses();
      const _courses = getCoursesResponse.data.data.map((course) => ({ id: course._id, name: course.courseTag, count: 0 }))
      const response = await StudentService.getCurrentStudents({ id: "" });
      response.data.data.forEach((student) => {
        let findIndex = _courses.findIndex((course) => course.id === student.courseId._id)
        if (findIndex !== -1) {
          _courses[findIndex].count += 1;
        }
      })
      setCourses(_courses)
    } catch (error) {
    }
  }
  useEffect(()=>{
    fetchStudents()
  },[])

  const barBackgroundColor = ['#95A4FC', '#BAEDBD', '#B1E3FF', '#A8C5DA', '#A1E3CB', '#7DC324', '#C6AAAA', '#AFF4C6']

  // Prepare labels based on unique years
  const labels = ["Course Disbursment Statistics"]

  // Prepare datasets based on data received
  const datasets = courses?.map((course, index) => {
    return {
      label: course.name,
      data: [course.count],
      backgroundColor: barBackgroundColor[index],
    }
  })

  const data = {
    labels,
    datasets,
  }

  return <Bar {...props} options={options} data={data} />
}
